// Styles
import styled from 'styled-components/macro'

// Core
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// Components, services, etc
import {getOrdersForAddress} from 'actions/ordersActions'
import ProductCard from 'components/ProductCard'
import {productGroupArray} from 'constants/productCardConstants'
import {
  ordersByAddressByProductTypeDictionarySelector,
  isUserAuthenticatedSelector,
  propertyDataAddressSelector
} from 'selectors'
import {getOrdersByAddressByProductGroupDictionary, isAvailable} from 'services/productsServices'
import type {ProductGroupType} from 'types/productTypes'
import type {PropertyType} from 'types/propertyTypes'
import useProductGroups from 'hooks/useProductGroups'
import {getAddressKey} from 'services/addressServices'

const sortByAvailability = (pid: PropertyType['pid']) => {
  const availableProducts: ProductGroupType[] = []
  const unAvailableProducts: ProductGroupType[] = []
  for (const productType of productGroupArray) {
    if (isAvailable(productType, {pid})) {
      availableProducts.push(productType)
    } else {
      unAvailableProducts.push(productType)
    }
  }
  return [...availableProducts, ...unAvailableProducts]
}

const PropertyProductsList = ({pid}: Pick<PropertyType, 'pid'>) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  const ordersByAddressByProductTypeDictionary = useSelector(
    ordersByAddressByProductTypeDictionarySelector
  )
  const address = useSelector(propertyDataAddressSelector)
  const {productGroups} = useProductGroups()
  const ordersByAddressByProductGroupDictionary = getOrdersByAddressByProductGroupDictionary(
    ordersByAddressByProductTypeDictionary,
    productGroups?.productGroups
  )
  const addressKey = address && getAddressKey(address)

  useEffect(() => {
    if (isLoggedIn && pid) {
      dispatch(getOrdersForAddress({PID: pid}))
    }
  }, [dispatch, isLoggedIn, pid])

  useEffect(() => {
    if (isLoggedIn && !pid && addressKey) {
      dispatch(getOrdersForAddress({addressKey}))
    }
  }, [dispatch, isLoggedIn, addressKey, pid])

  return (
    <PropertyProductsList.Styled>
      <h3>Ways to learn more</h3>
      <div className='cards-wrap'>
        {sortByAvailability(pid).map((productGroup, index) => {
          return (
            <ProductCard
              key={productGroup}
              productGroup={productGroup}
              orders={ordersByAddressByProductGroupDictionary[productGroup]}
              index={index}
            />
          )
        })}
      </div>
    </PropertyProductsList.Styled>
  )
}

PropertyProductsList.Styled = styled.div`
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin-bottom: 14px;
  }

  .cards-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
    row-gap: 24px;
  }
`

export default PropertyProductsList
