export const ORDER_DETAILS_TABS = [
  {label: 'Order Details', value: 'orderDetails', sqaPrefix: 'order-details'},
  {label: 'Loan Details', value: 'loanDetails', sqaPrefix: 'loan-details'},
  {label: 'Message Center', value: 'messageCenter', sqaPrefix: 'message-center'},
  {label: 'Access Details', value: 'accessDetails', sqaPrefix: 'access-details'}
] as const

export const ORDER_DETAILS_TABS_WITHOUT_ACCESS_DETAILS = [
  {label: 'Order Details', value: 'orderDetails', sqaPrefix: 'order-details'},
  {label: 'Loan Details', value: 'loanDetails', sqaPrefix: 'loan-details'},
  {label: 'Message Center', value: 'messageCenter', sqaPrefix: 'message-center'}
] as const

export const ORDER_DETAILS_DATA = [
  {key: 'productType', label: 'Product', sqaPrefix: 'product'},
  {key: 'clientTrackingId', label: 'Tracking ID', sqaPrefix: 'tracking-id'},
  {key: 'inspectionDate', label: 'Inspection Date', sqaPrefix: 'inspection-date'},
  {key: 'customerNotes', label: 'Order Instructions', sqaPrefix: 'order-instructions'}
] as const

export const ORDER_DETAILS_CDA_DATA = [
  {key: 'productType', label: 'Product', sqaPrefix: 'product'},
  {key: 'clientTrackingId', label: 'Tracking ID', sqaPrefix: 'tracking-id'},
  {key: 'customerNotes', label: 'Order Instructions', sqaPrefix: 'order-instructions'}
] as const

export const MAX_CHARACTERS_COMPOSE_MESSAGE = 3800
