import React, {Fragment, forwardRef} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {FieldArray} from 'react-final-form-arrays'

import AssignDocumentType from 'components/AssignDocumentType'
import FlatList from 'components/FlatList'
import type {DocumentUploadType} from 'types/orderTypes'
import type {OrderFormDocument, ProductGroupType} from 'types/productTypes'
import UploadButton from 'components/UploadButton'
import {useForm} from 'react-final-form'
import {validateUploadedDocuments} from 'services/formValidatorServices'
import {useSelector} from 'react-redux'
import {isDocumentUploadModalOpenSelector} from 'selectors'

interface UploadDocumentsProps {
  className?: string
  fieldName: string
  requiredDocuments?: DocumentUploadType[]
  isUploadDocumentRequired?: boolean
  productGroup: ProductGroupType
}

const UploadDocuments = forwardRef<HTMLDivElement, UploadDocumentsProps>(
  (
    {
      className,
      fieldName,
      requiredDocuments = [],
      isUploadDocumentRequired = false,
      productGroup
    }: UploadDocumentsProps,
    ref
  ) => {
    const {
      mutators: {push}
    } = useForm()
    const isDocumentUploadModalOpen = useSelector(isDocumentUploadModalOpenSelector)

    return (
      <StyledUploadDocuments className={classnames(className)} ref={ref}>
        <FieldArray<OrderFormDocument>
          name={fieldName}
          validate={value =>
            validateUploadedDocuments(
              requiredDocuments,
              value,
              isUploadDocumentRequired,
              isDocumentUploadModalOpen
            )
          }
        >
          {({fields, meta}) => (
            <Fragment>
              <UploadButton
                className='select-files-button'
                isMultiple={true}
                onSelectFiles={(files: FileList) => {
                  Object.values(files).forEach(file => {
                    push(fieldName, {
                      file
                    })
                  })
                }}
              />
              {/* We only care about errors return by validateRequiredDocuments. Unfortunately FieldArray will set meta.error to an
                    array containing the errors from the fields contained within the FieldArray if validateRequiredDocuments doesn't return an error.
                    Because of this we need to check is meta.error is a string since we know that means validateRequiredDocuments has returned an error.
                  */}
              {meta.submitFailed && meta.error && typeof meta.error === 'string' && (
                <p className='error'>{meta.error}</p>
              )}
              <FlatList
                className='upload-documents-flat-list'
                items={fields.map((name, index) => {
                  const {file, documentType} = fields.value[index]
                  return {
                    className: 'document-item',
                    key: name,
                    content: (
                      <AssignDocumentType
                        documentType={documentType}
                        document={file}
                        fieldName={name}
                        index={index}
                        productGroup={productGroup}
                        key={name}
                      />
                    )
                  }
                })}
                withDividers
              />
            </Fragment>
          )}
        </FieldArray>
      </StyledUploadDocuments>
    )
  }
)

const StyledUploadDocuments = styled.div`
  width: 100%;

  .file-upload-icon {
    width: 16px;
    color: ${({theme}) => theme.colors.grayscale.black};
    font-size: 1rem;
    margin-right: 4px;
  }

  .document-item {
    padding: 16px 0;

    &:first-child {
      padding-top: 24px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .error {
    color: ${({theme}) => theme.colors.ruby.base};
    font-size: 0.75rem;
    margin: 8px 0 0 0;
  }
`

export default UploadDocuments
