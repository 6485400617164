// Styles
import classnames from 'classnames'
import styled from 'styled-components/macro'

// Core
import React, {Dispatch, Fragment, SetStateAction, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

// Components, services, etc
import {openModal} from 'actions/modalActions'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ProductOrders from 'components/ProductCard/ProductOrders'
import {CLICKED_LEARN_MORE} from 'constants/mixpanelConstants'
import {LOG_IN_SIGN_UP_MODAL, USER_PROFILE_MODAL} from 'constants/modalsConstants'
import {
  AVM,
  CDA,
  BROKER_BASED_VALUATIONS,
  INSPECTION,
  productDataDictionaryHomePage,
  productDataDictionaryNonHomePage,
  PRODUCT_UNAVAILABLE_MESSAGE
} from 'constants/productCardConstants'
import type {ProductData} from 'constants/productCardConstants'
import {isUserAuthenticatedSelector, userSelector, propertyPidSelector} from 'selectors'
import {customerSelector, isInvoicePaySelector} from 'selectors/customerSelectors'
import {getMixpanelPage, trackMixpanelEvent} from 'services/mixpanel'
import {isExisty} from 'services/utils'
import type {OpsOrderType} from 'types/orderTypes'
import {ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP} from 'constants/orderFormConstants'
import type {OrderFormConfig, ProductGroupType} from 'types/productTypes'
import Tooltip from 'components/Tooltip'

// 3rd-party
import type {ChipProps} from 'components/Chip/Chip'
import type {PropertyType} from 'types/propertyTypes'
import useProductGroups from 'hooks/useProductGroups'
import {isProductGroupSupported, isProductGroupUnavailable} from 'services/productsServices'

type Props = {
  index?: number
  orders?: Array<OpsOrderType>
  'data-sqa-id'?: string
  productGroup: ProductGroupType
}

type ProductCardProps = {
  handleBuyNow: React.MouseEventHandler<HTMLButtonElement>
  productData: ProductData
  handleLearnMoreClick: React.MouseEventHandler<HTMLButtonElement>
  isInvoicePay: boolean
  productGroup: ProductGroupType
  isHovered: boolean
  setIsHovered: Dispatch<SetStateAction<boolean>>
  index?: number
  isUserOnHomePage?: boolean
  isLoggedIn?: boolean
  isProductUnavailable?: boolean
} & Pick<Props, 'orders'> &
  Pick<PropertyType, 'pid'>

const ProductCard = ({index, productGroup, orders}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isUserOnHomePage = location.pathname === '/products'
  const productData = isUserOnHomePage
    ? productDataDictionaryHomePage[productGroup]
    : productDataDictionaryNonHomePage[productGroup]
  const isInvoicePay = useSelector(isInvoicePaySelector)
  const [isHovered, setIsHovered] = useState(false)
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  const shouldShowPrice = isExisty(productData.startingPrice) && isLoggedIn
  const hasOrders = Boolean(orders && orders?.length > 0)
  const customer = useSelector(customerSelector)
  const userData = useSelector(userSelector)
  const {firstName, lastName, email} = userData
  const pid = useSelector(propertyPidSelector)
  const {productGroups, productGroupsError} = useProductGroups()
  const isSupported = isProductGroupSupported(productGroup, productGroups?.productGroups)
  const isProductUnavailable =
    !isUserOnHomePage && isLoggedIn
      ? isProductGroupUnavailable({
          productGroup,
          pid,
          isSupported,
          hasCustomerInfo: Boolean(customer),
          error: productGroupsError
        })
      : false

  const isRequiredInfoReady = !!(
    firstName &&
    lastName &&
    email &&
    customer?.name &&
    customer.billingContact?.email &&
    customer.billingContact?.lastName &&
    customer.billingContact?.firstName
  )

  const priceText = isProductUnavailable ? (
    <Tooltip className='tooltip' text={PRODUCT_UNAVAILABLE_MESSAGE}>
      Contact Support
    </Tooltip>
  ) : (
    `${[CDA, AVM, BROKER_BASED_VALUATIONS, INSPECTION].includes(productGroup) ? 'From' : ''} $${
      productData.startingPrice
    }`
  )

  const openOrderForm = (orderFormConfig: OrderFormConfig) =>
    dispatch(openModal({modalType: 'ORDER_FORM_MODAL', orderFormConfig}))

  const handleBuyNow = () => {
    if (isUserOnHomePage) {
      handleMixpanelLearnMoreEvent()
      window.open(productData.learnMoreLink)
    } else {
      if (isLoggedIn) {
        const customerInformationRequiredProducts = [CDA, BROKER_BASED_VALUATIONS, INSPECTION]
        if (customerInformationRequiredProducts.includes(productGroup) && !isRequiredInfoReady) {
          dispatch(
            openModal({
              modalType: USER_PROFILE_MODAL,
              title: 'Complete your profile to continue',
              subTitle: 'To place your order, please confirm the following details',
              onConfirmText: 'Continue ->',
              mixpanelEventData: {
                initiatedFrom: 'CDA checkout Modal'
              },
              redirectToProduct: productGroup
            })
          )
        } else {
          openOrderForm(ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP[productGroup as ProductGroupType])
        }
      } else {
        dispatch(
          openModal({
            modalType: LOG_IN_SIGN_UP_MODAL,
            mode: 'signUp',
            mixpanelEventData: {
              initiatedFrom: 'Product Card'
            }
          })
        )
      }
    }
  }

  const handleMixpanelLearnMoreEvent = () => {
    const page = getMixpanelPage(location.pathname)
    trackMixpanelEvent(CLICKED_LEARN_MORE, {product: productGroup, page})
  }

  return (
    <ProductCard.Styled className='product-card' $productColor={productData.productColor}>
      <div className='side left'>
        <div
          onMouseOver={e => {
            setIsHovered(true)
          }}
          onMouseOut={e => {
            setIsHovered(false)
          }}
        >
          <div className={classnames('image-wrapper', {'learn-more-background': isHovered})}>
            <Button
              variant='secondary'
              title={
                <Fragment>
                  <span className='text'>Learn More</span>
                  <Icon className='launch-icon' icon='launch' />
                </Fragment>
              }
              className='learn-more-button'
              sqaPrefix={`learn-more-${index}`}
              onClick={() => {
                handleMixpanelLearnMoreEvent()
                window.open(productData.learnMoreLink)
              }}
            />
            <img
              src={productData.promoImg}
              alt={`${productData.name} Product`}
              className={classnames('product-image', {'learn-more-background': isHovered})}
              data-sqa-id={`image-${index}`}
            />
          </div>
          <h5 className='title' data-sqa-id={`title-${index}`}>
            {productData.title}
          </h5>
          {shouldShowPrice && (
            <div className='price' data-sqa-id={`price-${index}`}>
              {priceText}
            </div>
          )}
          <p
            className='description'
            data-sqa-id={`description-${index}`}
          >{`${productData.popDescription} `}</p>
        </div>
        <div className='actions'>
          <Button
            className={classnames('action-button', {
              'action-button-width': hasOrders,
              'action-button-disabled': isProductUnavailable
            })}
            variant='secondary'
            title={
              <ProductCard.ButtonLabel
                isLoggedIn={isLoggedIn}
                productGroup={productGroup}
                isInvoicePay={isInvoicePay}
                hasOrders={hasOrders}
                isUserOnHomePage={isUserOnHomePage}
                isProductUnavailable={isProductUnavailable}
              />
            }
            onClick={handleBuyNow}
            sqaPrefix={`action-${index}`}
            disabled={isProductUnavailable}
          />
          <ProductOrders orders={orders} />
        </div>
      </div>
    </ProductCard.Styled>
  )
}

ProductCard.ButtonLabel = ({
  isLoggedIn,
  isUserOnHomePage,
  hasOrders,
  isProductUnavailable
}: Pick<
  ProductCardProps,
  'isLoggedIn' | 'isInvoicePay' | 'productGroup' | 'isUserOnHomePage' | 'isProductUnavailable'
> & {
  hasOrders: boolean
}) => {
  let content

  if (isUserOnHomePage) {
    content = (
      <Fragment>
        Learn More
        <Icon className='launch-icon' icon='launch' />
      </Fragment>
    )
  } else if (isProductUnavailable) {
    content = <React.Fragment>Unavailable</React.Fragment>
  } else if (isLoggedIn) {
    content = <React.Fragment>{hasOrders ? 'Order' : 'Order Now'}</React.Fragment>
  } else {
    content = <React.Fragment>Get Started</React.Fragment>
  }

  return content
}

ProductCard.Styled = styled.div<{$productColor: ChipProps['color']}>`
  width: 273px;
  .description {
    margin-top: 0;
    margin-bottom: 13px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${({theme}) => theme.colors.grayscale.gray};
  }

  .launch-icon {
    margin-left: 8px;
    font-size: 0.9rem;
  }

  &.product-card {
    padding: 0;
    display: flex;

    .side {
      flex: 1 0 auto;
      width: 100%;

      &.left {
        .product-image {
          width: 100%;
          border-radius: 5px;
          z-index: 50;
        }
        .image-wrapper {
          position: relative;
          border-radius: 5px;
          width: 273px;
          height: 160px;
          overflow: hidden;

          &.learn-more-background {
            background: ${({theme}) => theme.colors.grayscale.black};
            .product-image {
              opacity: 0.5;
              transition: all 0.2s ease-out;
              z-index: 25;
            }

            .learn-more-button {
              display: block;
            }
          }

          .learn-more-button {
            display: none;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            z-index: 10;
            min-width: 132px;
          }
        }

        .title {
          font-size: 1rem;
          margin-top: 16px;
          white-space: nowrap;
        }

        .price {
          margin-bottom: 8px;
        }

        .description {
          margin-bottom: 16px;
          width: 273px;
          height: 80px;
          .trimmed-content-container {
            .content-wrap {
              height: 41px;

              p {
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: ${({theme}) => theme.colors.grayscale.gray};
              }
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;

          .action-button {
            width: 100%;
            color: ${({theme}) => theme.colors.grayscale.white};
            background: ${({theme}) => theme.colors.grayscale.black};

            &.action-button-width {
              width: 71px;
            }

            &:disabled {
              background: ${({theme}) => theme.colors.stone.lighter};
              border: 1px solid ${({theme}) => theme.colors.stone.lighter};
              color: ${({theme}) => theme.colors.stone.base};
            }
          }
          p {
            color: ${({theme}) => theme.colors.grayscale.gray};
            margin: 0 0 0 auto;
          }
          h5 {
            margin-bottom: 0;
            margin-left: 6px;
            font-weight: 400;
            display: inline;
          }
        }

        .not-available {
          margin-left: -2.5%;
          margin-bottom: 0;
          width: 105%;
        }
      }

      &.right {
        background-color: ${({theme, $productColor}) => theme.colors[$productColor].lightest};
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 40px 40px 0;

        img {
          width: 100%;
        }
      }
    }
  }
`

export default ProductCard
