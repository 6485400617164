import {PROD_ORIGIN} from 'constants/appConstants'

const PROD_TOKEN = 'd922c821cbc1bea208be2be2478d5719'
const DEV_TOKEN = '69fed061d35fb796cddfa6727ac07e82'

const getToken = () => {
  let token = DEV_TOKEN
  if (process.env.NODE_ENV === 'production' && window.location.origin === PROD_ORIGIN) {
    token = PROD_TOKEN
  }
  return token
}

export const MIXPANEL_TOKEN = getToken()

// EVENT TYPES
export const APP_LOADED = 'App Loaded'
export const VISITED_LANDING_PAGE = 'Visited Landing Page'
export const CLICKED_LEARN_MORE = 'Clicked Learn More'
export const LOGGED_IN = 'Logged In'
export const SIGNED_UP = 'Signed Up'
export const SEARCHED_ADDRESS = 'Searched Address'
export const LAUNCHED_PRODUCT = 'Launched Product'
export const OPENED_MODAL = 'Opened Modal'
export const APP_UNMOUNTED = 'App Unmounted'
export const DOWNLOADED_PDF = 'Downloaded PDF'
export const INVITE_USER = 'Invite User'
export const RESEND_INVITE = 'Resend Invite'
export const CLICKED_NAVIGATION = 'Clicked Navigation'
export const CLICKED_TAB = 'Clicked Tab'
export const PURCHASED_PRODUCT = 'Purchased Product'
export const EDIT_CUSTOMER_USER = 'Edited user'
export const CUSTOMER_USER_CLICKED_FILTER = 'Clicked Filter'
export const CUSTOMER_USER_CLICKED_SORT = 'Clicked Sort'
export const CHOOSE_DOCUMENT = 'Choose Document'
export const DELETE_DOCUMENT = 'Delete Document'
export const DELETE_CONTACT = 'Delete Contact'
export const SELECT_DOCUMENT_TYPE = 'Select Document Type'
export const ADD_CONTACT = 'Add Contact'
export const COMPOSE_MESSAGE = 'Compose Message'
export const SEND_MESSAGE = 'Send Message'

// PAGE TYPES
export const LANDING_PAGE = 'Landing Page'
export const PROPERTY_OVERVIEW_PAGE = 'Property Overview Page'
export const ORDERS_LIST_PAGE = 'Orders List Page'
export const FEDERATED_LOGIN_PAGE = 'Federated Login Page'

export const SKIP_EVENT = 'SKIP_EVENT'
