import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {useForm} from 'react-final-form'

import DetailsSection from 'components/OrderForm/DetailsSection'
import UploadDocuments from 'components/UploadDocuments'
import type {DocumentUploadType} from 'types/orderTypes'
import type {OrderFormType, ProductGroupType} from 'types/productTypes'
import {ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE} from 'constants/orderDocumentConstants'
import Icon from 'components/Icon'
import {filterConditionallyRequiredDocuments} from 'services/documentsServices'

export interface UploadDocumentsSectionProps {
  requiredDocuments: DocumentUploadType[]
  productGroup: ProductGroupType
}

const UploadDocumentsSection = ({requiredDocuments, productGroup}: UploadDocumentsSectionProps) => {
  const {getFieldState} = useForm()
  const purposeField = getFieldState('purpose')
  const documentsField = getFieldState('customerDocumentList')
  const documents: OrderFormType['documents'] = documentsField?.value
  const uploadDocumentsSectionRef = useRef<HTMLDivElement>(null)
  const detailsSectionRef = useRef<HTMLDivElement>(null)
  const uploadDocumentsRef = useRef<HTMLDivElement>(null)

  // get relevant positioning data from refs for use in dynamic styling
  const uploadDocumentsSectionTop =
    uploadDocumentsSectionRef?.current?.getBoundingClientRect().top ?? 0
  const detailsSectionSubtitleEl = detailsSectionRef?.current?.querySelector('.subtitle')
  const detailsSectionSubtitleBottom = detailsSectionSubtitleEl?.getBoundingClientRect().bottom ?? 0
  const detailsSectionSubtitleMarginBottom =
    (detailsSectionSubtitleEl &&
      parseInt(window.getComputedStyle(detailsSectionSubtitleEl).marginBottom)) ??
    0
  const uploadDocumentsTop = uploadDocumentsRef?.current?.getBoundingClientRect().top ?? 0
  const relativeBottomOfDetailsSectionSubtitle =
    detailsSectionSubtitleBottom - uploadDocumentsSectionTop + detailsSectionSubtitleMarginBottom
  const relativeTopOfUploadDocuments = uploadDocumentsTop - uploadDocumentsSectionTop

  useEffect(() => {
    uploadDocumentsSectionRef.current?.scrollIntoView({behavior: 'smooth'})
  }, [documents])

  const filteredRequiredDocuments = filterConditionallyRequiredDocuments(requiredDocuments, {
    loanPurposeType: purposeField?.value
  })

  const hasDocumentTypeAssigned = (documentType: DocumentUploadType): boolean =>
    !!documents?.find(document => document.documentType === documentType)

  const renderRequiredDocuments = () => {
    return (
      <div className='required-documents'>
        <h5 className='required-documents-title'>Required Uploads:</h5>
        <ul className='required-documents-list'>
          {filteredRequiredDocuments.map(requiredDocument => {
            let icon
            let status = ''
            if (hasDocumentTypeAssigned(requiredDocument)) {
              icon = 'check_circle'
              status = 'success'
            } else if (documentsField?.submitFailed && documentsField?.invalid) {
              icon = 'error'
              status = 'failed'
            }

            return (
              <li
                key={requiredDocument}
                className={classnames('required-documents-list-item', {
                  'required-documents-list-item-with-icon': !!icon
                })}
              >
                {icon && (
                  <Icon
                    className={`required-documents-list-item-icon required-documents-list-item-with-icon-${status}`}
                    icon={icon}
                  />
                )}
                {!icon && <span className='required-documents-list-item-bullet'>•</span>}
                {ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE[requiredDocument].label}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <UploadDocumentsSection.Styled
      ref={uploadDocumentsSectionRef}
      $relativeBottomOfDetailsSectionSubtitle={relativeBottomOfDetailsSectionSubtitle}
    >
      <DetailsSection
        className='upload-documents-section'
        title='Upload Documents'
        subtitle='Ensure you upload all required documents'
        renderHeaderRight={
          filteredRequiredDocuments.length > 0 ? renderRequiredDocuments : undefined
        }
        ref={detailsSectionRef}
      >
        <UploadDocuments
          className={classnames({
            // only override the styling of UploadDocuments if the required documents list is
            // long enough to cause the UploadDocuments component to be pushed down lower
            // than the bottom of the DetailSection's subtitle
            'order-form-upload-documents-override':
              relativeTopOfUploadDocuments > relativeBottomOfDetailsSectionSubtitle
          })}
          fieldName='customerDocumentList'
          requiredDocuments={filteredRequiredDocuments}
          ref={uploadDocumentsRef}
          productGroup={productGroup}
        />
      </DetailsSection>
    </UploadDocumentsSection.Styled>
  )
}

UploadDocumentsSection.Styled = styled.div<{
  $relativeBottomOfDetailsSectionSubtitle: number
}>`
  position: relative;

  .upload-documents-section {
    min-height: 150px; // make sure section contains the select files button when it is positioned absolutely

    header {
      min-height: 102px; // make sure UploadDocuments content is always below the select files button
    }

    .header-left {
      min-width: 301px; // ensure subtitle text doesn't wrap
      padding-right: 24px; // match the padding-right in the required documents div
    }
    .header-right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .order-form-upload-documents-override {
    .select-files-button {
      position: absolute;
      top: ${({$relativeBottomOfDetailsSectionSubtitle}) =>
        `${$relativeBottomOfDetailsSectionSubtitle}px`};
    }
  }
  .required-documents {
    border-left: 1px solid ${({theme}) => theme.colors.stone.light};
    padding-left: 24px;

    &-title {
      margin-bottom: 6px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-item {
        align-items: center;
        display: flex;

        &-icon,
        &-bullet {
          width: 16px;
          font-size: 16px;
          margin-right: 4px;
        }

        &-bullet {
          text-align: center;
        }

        &-with-icon {
          &-success {
            color: ${({theme}) => theme.colors.emerald.base};
          }

          &-failed {
            color: ${({theme}) => theme.colors.ruby.base};
          }
        }
      }
    }
  }
`

export default UploadDocumentsSection
