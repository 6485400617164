// Core
import React from 'react'

// Components, services, constants
import Card from 'components/Card'
import FlatList from 'components/FlatList'
import LabelValuePair from 'components/LabelValuePair'
import type {OpsOrderType, ProductDescription} from 'types/orderTypes'
import Chip from 'components/Chip'
import {getFormattedDate} from 'services/dateServices'
import styled from 'styled-components'
import useGetUpdatedOrderDetailsData from 'hooks/useGetUpdatedOrderDetailsData'
import {NO_DATA} from 'constants/appConstants'

const OrderDetailsCard = ({
  order,
  productDescription
}: {
  order: OpsOrderType
  productDescription?: ProductDescription
}) => {
  const orderDetailsData = useGetUpdatedOrderDetailsData()
  const inspection = order?.status?.inspection
  const inspectionDate = inspection?.inspectionDate
  const isCompleted = inspection?.isCompleted
  const formattedOrderData = {
    productType: productDescription,
    inspectionDate: inspectionDate ? (
      <span>
        {getFormattedDate(inspectionDate)}
        {isCompleted && <Chip className='completed-chip' label='Completed' color='emerald' />}
      </span>
    ) : undefined,
    clientTrackingId: order.clientTrackingId,
    customerNotes: order.customerNotes
  }

  return (
    <OrderDetailsCard.Styled>
      <FlatList
        items={orderDetailsData.map(data => {
          let result = {key: '', content: <span>{NO_DATA}</span>}
          if (data.key) {
            result = {
              key: data.key,
              content: (
                <LabelValuePair
                  label={data.label}
                  value={formattedOrderData[data.key]}
                  sqaPrefix={data.sqaPrefix}
                />
              )
            }
          }
          return result
        })}
        withDividers
      />
    </OrderDetailsCard.Styled>
  )
}

export default OrderDetailsCard

OrderDetailsCard.Styled = styled(Card)`
  .completed-chip {
    margin-left: 8px;
  }
`
