import React, {Fragment} from 'react'
import {Form} from 'react-final-form'
import styled from 'styled-components'
import arrayMutators from 'final-form-arrays'
import {useDispatch} from 'react-redux'

import Modal from 'components/Modal'
import {resetModal} from 'actions/modalActions'
import {resetDocumentsState} from 'actions/documentsActions'
import UploadDocuments from 'components/UploadDocuments'
import Button from 'components/Button'
import useGetProductGroup from 'hooks/useGetProductGroup'

interface DocumentUploadModalProps {
  className?: string
  open: boolean
  handleClose: () => void
}

export const DocumentUploadModal = ({className, open, handleClose}: DocumentUploadModalProps) => {
  const dispatch = useDispatch()
  const productGroup = useGetProductGroup()

  const handleCloseAndResetModal = () => {
    handleClose()
    dispatch(resetModal())
  }

  const onClose = () => {
    handleCloseAndResetModal()
    dispatch(resetDocumentsState())
  }

  const onSubmit = () => {
    onClose()
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      render={({
        dirtySinceLastSubmit,
        handleSubmit,
        hasSubmitErrors,
        submitting,
        hasValidationErrors,
        submitFailed
      }) => {
        return (
          <DocumentUploadModal.Styled
            className={className}
            open={open}
            closeOnBackdropClick={false}
            disableEnforceFocus
            showCloseButton
            size='md'
            onClose={onClose}
          >
            <Modal.Header>Upload Documents</Modal.Header>
            <Modal.Content>
              <Fragment>
                <div className='subtitle'>
                  Documents will be received by Clear Capital's support team
                </div>
                <UploadDocuments
                  className='upload-documents'
                  fieldName='customerDocumentList'
                  productGroup={productGroup}
                />
              </Fragment>
            </Modal.Content>
            <Modal.Footer>
              <Button variant='secondary' title='Cancel' size='large' onClick={onClose} />
              <Button
                className='btn-submit'
                variant='primary'
                disabled={
                  submitting ||
                  (submitFailed && hasValidationErrors) ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                title='Upload Document'
                type='submit'
                size='large'
                onClick={handleSubmit}
              />
            </Modal.Footer>
          </DocumentUploadModal.Styled>
        )
      }}
    />
  )
}

DocumentUploadModal.Styled = styled(Modal)`
  .subtitle {
    color: ${({theme}) => theme.colors.grayscale.gray};
  }
  .upload-documents {
    margin-top: 24px;
    .upload-documents-flat-list {
      margin-top: 16px;
      .flat-list-item {
        background-color: ${({theme}) => theme.colors.stone.lighter};
        padding: 24px;
      }
    }
  }
  .btn-submit {
    width: 165px;
  }
`

export default DocumentUploadModal
