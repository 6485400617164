import {useParams} from 'react-router-dom'
import {opsApi} from 'services/apis'
import useSWR from 'swr'
import {GetDocumentsByOpsOrderIdResponse} from 'types/orderTypes'
import useGetProductGroup from './useGetProductGroup'
import {BROKER_BASED_VALUATIONS, CDA, INSPECTION} from 'constants/productCardConstants'

export default function useGetDocumentByOpsOrderId() {
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const getUrl = `orders/${opsOrderId}/documents`
  const productGroup = useGetProductGroup()

  const {data, error, isLoading} = useSWR(
    [BROKER_BASED_VALUATIONS, CDA, INSPECTION].includes(productGroup) ? getUrl : null,
    () => opsApi.get<GetDocumentsByOpsOrderIdResponse>(getUrl),
    {revalidateOnFocus: false, shouldRetryOnError: false}
  )

  return {data, error, isLoading}
}
